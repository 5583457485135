import React from 'react';
import Content from '../components/Content';
import Hero from '../components/Hero';

function ContactPage(props) {
    return (
        <div>
            <Hero title={props.title}/>
            <Content>
                <h3>Email : <a href = "mailto:shubhamkumar.patel@epita.fr">shubhamkumar.patel@epita.fr</a></h3>
                <h3>
                    Linkedin : <a href="https://www.linkedin.com/in/shubhamkumar-patel/">Shubhamkumar Patel</a>
                </h3>
                <h3>
                    Twitter : <a href="https://twitter.com/ShubhxP">ShubhxP</a>
                </h3>
                <h3>
                    Github : <a href="https://github.com/Shubh023">Shubh023</a>
                </h3>
            </Content>
        </div>
    );
}

export default ContactPage;
