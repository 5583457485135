import React from 'react';
import Content from '../components/Content';
import Hero from '../components/Hero';
import {ProgressBar} from "react-bootstrap";

function CodingPage(props) {
    return (
        <div>
            <Hero title={props.title}/>
            <Content>
            <h2>Programming skillset :</h2>
            <p>C / C++<ProgressBar now={87} animated={true} label={"Advanced : 5+ years"}/></p>
            <p>Python<ProgressBar now={95} animated={true} label={"Advanced : 5+ years"}/></p>
            <p>CUDA<ProgressBar now={85} animated={true} label={"Advanced : 2+ years"}/></p>
            <p>Tensorflow / Keras / PyTorch<ProgressBar now={80} animated={true} label={"Advanced : 3+ years"}/></p>
            <p>Scikit-Learn<ProgressBar now={78} animated={true} label={"Intermediate : 3+ years"}/></p>
            <p>Scikit-Image<ProgressBar now={77} animated={true} label={"Intermediate : 2+ years"}/></p>
            <p>Data-Structures<ProgressBar now={75} animated={true} label={"Intermediate : 3+ years"}/></p>
            <p>OpenGL / GLSL<ProgressBar now={65} animated={true} label={"Intermediate : 2+ years"}/></p>
            <p>Git<ProgressBar now={75} animated={true} label={"Intermediate to advanced : 5+ years"}/></p>
            <p>Docker<ProgressBar now={80} animated={true} label={"Intermediate to advanced : 2+ years"}/></p>
            <p>PostgreSQL<ProgressBar now={60} animated={true} label={"Intermediate : 2+ years"}/></p>
            <p>Java<ProgressBar now={55} animated={true} label={"Intermediate : 2+ years"}/></p>
            <p>Django / Flask<ProgressBar now={40} animated={true} label={"Intermediate : 1+ years"}/></p>

            <p> . </p>

            <h2>Projects I have done : </h2>
            <table>
                <tr>
                    <td>Disparity map using GraphCut method (C++)</td>
                    <td>3D mesh generation from two 2D images by generating a Disparity-Map using GraphCut method.</td>
                </tr>
                <tr>
                    <td>Disparity map computation by seeds / region propagation (C++)</td>
                    <td>Generate simple Disparity-Map from 2D images to get 3D reconstruction of a scene.</td>
                </tr>
                <tr>
                    <td>Panorama (C++)</td>
                    <td>Stitching two images into one Panorama image with key-point detection using Computer Vision.</td>
                </tr>
                <tr>
                    <td>GPGPU (CUDA)</td>
                    <td>End to end application for real-time bar-code detection implemented using CUDA and parallel programming concepts utilizing GPUs.</td>
                </tr>
                <tr>
                    <td>IML (Python, Scikit-learn)</td>
                    <td>Machine Learning pipeline for classifying hyper-spectral satellite images (Indianapines and Salinas)</td>
                </tr>
                <tr>
                    <td>Navires (Python, Tensorflow, Keras)</td>
                    <td>Create and train a CNN to classify different categories of vessels on images. Then implement similar model using transfer learning by importing weights from an existing model and adapt it to current classification task.</td>
                </tr>
                <tr>
                    <td>TIFO - IMED (Scikit-learn, Scikit-Image)</td>
                    <td>Segmentation and extraction of key-points from Bee wings to identify their species.</td>
                </tr>
            </table>
            </Content>
        </div>
    );
}

export default CodingPage;
