import React from 'react';
import Content from '../components/Content';
import Hero from '../components/Hero';

function PhotosPage(props) {
    return (
        <div>
            <Hero title={props.title}/>
            <Content>
                <h2>/!\ Work in Progress /!\</h2>
            </Content>
        </div>
    );
}

export default PhotosPage;