import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Footer() {
    return (
       <footer className="mt-5">
            <Container>
                <Row className="border-top justify-content-between p-3">
                    <Col className="p-0" md={3} sm={12}>
                        <Link className="nav-link g-brand" to="/">SP</Link>
                    </Col>
                    <Col className="p-0 d-flex justify-content-end" md={3}>
                        Made by Shubhamkumar Patel
                    </Col>
                </Row>
            </Container>
       </footer>
    );
}

export default Footer;
