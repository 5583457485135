import React from 'react';
import Hero from '../components/Hero';
import ProjectsCarousel from '../components/ProjectsCarousel';

function ProjectsPage(props) {
    return (
        <div>
            <Hero title={props.title}/>
            <ProjectsCarousel />
        </div>
    );
}

export default ProjectsPage;
