import React from 'react';
import Content from '../components/Content';
import Hero from '../components/Hero';

function AboutPage(props) {
    return (
        <div>
            <Hero title={props.title}/>
            <Content>
                <h1>Hello, my name is Shubham.</h1>
                <p>I am Currently looking for an end of year internship within my chosen field, one that will challenge me
and allow me to use my education, skills and past experiences in a way that is mutually
beneficial to both myself and my employer and allow for future growth and advancement.</p>
                <p>I'd like to pursue my career in the field of Machine learning
                    and Computer Vision especially in the real-time applications that derive from these topics such as AR, VR, Autonomous systems and other applications for embedded systems.</p>

                <p>.</p>

                <h1>Education : </h1>
                <p><h4>Sorbonne University, Paris, FR (September 2021 - Present)</h4>
                    <p>Master 2 - IMA (Double Degree with my IMAGE major at EPITA) Image processing, Computer Vision, Machine Learning and Computer graphics</p></p>

                <p><h4>EPITA, Paris, FR (September 2019 - Present)</h4>
                <p>IMAGE 2022 (Major) - Engineering Image processing, Computer Vision, Machine Learning and Computer graphics</p></p>

                <p><h4>Lycée Benjamin Fraklin, Orléans, FR (September 2017 - July 2019)</h4>
                <p>PT (Physics & Technology) Preparatory Classes</p></p>

                <p>.</p>

                <h1>Professional Experience : </h1>
                <p><h4>DXOMARK, Paris, FR (February 2021 - January 2022)</h4>
                <p><h5>Intern Developper</h5></p>
                <p>Create an advanced video player in form of a Qt Widget in C++. Create bindings between a Python based Qt application and the C++ player, in order to integrate the player within an internal application for optimized Video analysis.</p>
                <p>Tools : Python, C++, Qt, PySide2 and QtCreator</p></p>

                <p><h4>SNCF Réseau, Paris, FR (November 2020 - January 2021)</h4>
                <p><h5>Intern Backend Developper</h5></p>
                <p>Create API and tools to allow relations between multiple API and databases within the current infrastructure. Create documentation on the created API and tools so that all APIs and database on current platform can be integrated with the tools that were developed during the internship.</p>
                <p>Tools : Python, Django, Django-RestFramework and OpenAPI</p></p>

                <p>.</p>

                <h3>Languages I can speak: </h3>
                <table>
                    <tr>
                        <td>English : </td>
                        <td> Fluent</td>
                    </tr>
                    <tr>
                        <td>French : </td>
                        <td> Fluent</td>
                    </tr>
                    <tr>
                        <td>Hindi : </td>
                        <td> Native Tongue</td>
                    </tr>
                    <tr>
                        <td>Gujarati : </td>
                        <td> Native Tongue</td>
                    </tr>
                    <tr>
                        <td>Spanish : </td>
                        <td> Practiced</td>
                    </tr>
                    <tr>
                        <td>Russian : </td>
                        <td> Novice</td>
                    </tr>
                </table>

            </Content>
        </div>
    );
}

export default AboutPage;
