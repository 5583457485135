import React from 'react';
import { Container, Row } from 'react-bootstrap';

import Card from './Card'

import github from '../assets/images/github.jpg';
import tifo_imed from '../assets/images/tifo_imed.png';
import gpgpu from '../assets/images/gpgpu.png';
import navires from '../assets/images/navires.jpg';
import graphcut from '../assets/images/graphcut.png';
import seeds_prop from '../assets/images/seeds_prop.gif';
import ivtk from '../assets/images/ivtk.png';
import shadegl from '../assets/images/shadegl.gif';
import iml from '../assets/images/iml.png';
import ml_deepl from '../assets/images/ML.png';
import opticalflow from '../assets/images/OpticalFlow.gif';
import faceanalysis from '../assets/images/FaceAnalysis.png';
import cuda_raytracer from '../assets/images/cuda_raytracer.png';

class ProjectsCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: "Panorama",
                    subTitle: "Panorama from two input images",
                    text: "C++, Imagine++",
                    imgSrc: github,
                    link :"https://github.com/Shubh023/Panorama",
                    selected:false
                },
                {
                    id: 1,
                    title: "GraphCut - Disparity Maps",
                    subTitle: "Generate 3D disparity Map using Graphcut Algorithm",
                    text: "C++, Imagine++",
                    imgSrc: graphcut,
                    link :"https://github.com/Shubh023/Graphcut",
                    selected:false
                },
                {
                    id: 2,
                    title: "Seeds Propagation - Disparity Maps",
                    subTitle: "Generate 3D disparity Map using Seeds Propagation",
                    text: "C++, Imagine++",
                    imgSrc: seeds_prop,
                    link :"https://github.com/Shubh023/SeedsPropagation",
                    selected:false
                },
                {
                    id: 3,
                    title: "Navires",
                    subTitle: "Classify Naval Vessels using Deep Learning CNN",
                    text: "Python, Tensorflow, Keras",
                    imgSrc: navires,
                    link :"https://github.com/Shubh023/Navires",
                    selected:false
                },
                {
                    id: 4,
                    title: "GPGPU",
                    subTitle: "Real-Time barcode detection + segmentation using CUDA",
                    text: "CUDA, C++, Python",
                    imgSrc: gpgpu,
                    link :"https://github.com/Shubh023/GPGPU",
                    selected:false
                },
                {
                    id: 5,
                    title: "Raytracer",
                    subTitle: "Realistic Rendering with a raytracer engine, in CUDA & C++",
                    text: "CUDA, C++",
                    imgSrc: cuda_raytracer,
                    link :"https://github.com/Shubh023/cuda-raytracer",
                    selected:false
                },
                {
                    id: 6,
                    title: "IML",
                    subTitle: "Classify regions of HSI (Salinas & IndianaPines)",
                    text: "Python, Scikit-learn",
                    imgSrc: iml,
                    link :"https://github.com/Shubh023/IML",
                    selected:false
                },
                {
                    id: 7,
                    title: "TIFO-IMED",
                    subTitle: "Segmentation of Veins in Bee Wings",
                    text: "Python, Sckit-Image",
                    imgSrc: tifo_imed,
                    link :"https://github.com/Shubh023/TIFO-IMED",
                    selected:false
                },
                {
                    id: 8,
                    title: "ITK-VTK",
                    subTitle: "Segmentation and Visualization of MRI Scan",
                    text: "Python, ITK, VTK",
                    imgSrc: ivtk,
                    link :"https://github.com/Shubh023/ITK-VTK",
                    selected:false
                },
                {
                    id: 9,
                    title: "OpenGL",
                    subTitle: "3D Computer Graphics - Project",
                    text: "C++, OpenGL, GLFW, GLEW",
                    imgSrc: shadegl,
                    link :"https://github.com/Shubh023/ShadeGL",
                    selected:false
                },
                {
                    id: 10,
                    title: "SkippeX",
                    subTitle: "3D Computer Graphics - Project",
                    text: "C++, OpenGL, GLFW, GLEW",
                    imgSrc: shadegl,
                    link :"https://github.com/Shubh023/SkippeX",
                    selected:false
                },
                {
                    id: 11,
                    title: "Machine Learning",
                    subTitle: "Working with Neural Networks",
                    text: "Python, Tensorflow, PyTorch",
                    imgSrc: ml_deepl,
                    link :"https://github.com/Shubh023/ML-DeepLearning",
                    selected:false
                },
                {
                    id: 12,
                    title: "Face Analysis",
                    subTitle: "Face Detection & Analysis Method",
                    text: "Python",
                    imgSrc: faceanalysis,
                    link :"https://github.com/Shubh023/FaceAnalysis",
                    selected:false
                },
                {
                    id: 13,
                    title: "Optical Flow",
                    subTitle: "Optical Flow & Motion Detection Algorithms",
                    text: "Python",
                    imgSrc: opticalflow,
                    link :"https://github.com/Shubh023/OpticalFlow",
                    selected:false
                },
            ]
        }
    }

    handleCardClick = (id, card) => {

        let items = [...this.state.items];
        items[id].selected = items[id].selected ? false : true;
        items.forEach(item => {
            if(item.id !== id){
                item.selected = false;
            }
        });
        this.setState({
            items
        });
    }

    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id}/>
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
            </Container>
        );
    }
}

export default ProjectsCarousel;
