import React from 'react';
import { Container, Row } from 'react-bootstrap';

import Card from './Card'

import github from '../assets/images/github.jpg';
import automation from '../assets/images/automation.jpg';
import linkedin from '../assets/images/linkedin.jpg';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: "Github",
                    subTitle: "Git Source for my projects",
                    imgSrc: github,
                    link :"https://github.com/Shubh023",
                    selected:false
                },
                {
                    id: 1,
                    title: "Automation Engineer",
                    subTitle: "Blogs on Automation Projects",
                    imgSrc: automation,
                    link :"https://engineeringautonom.wixsite.com/automation",
                    selected:false
                },
                {
                    id: 2,
                    title: "Linkedin",
                    subTitle: "Social Network for professionals",
                    imgSrc: linkedin,
                    link :"https://www.linkedin.com/in/shubhamkumar-patel/.wixsite.com/",
                    selected:false
                },
            ]
        }
    }

    handleCardClick = (id, card) => {

        let items = [...this.state.items];
        items[id].selected = items[id].selected ? false : true;
        items.forEach(item => {
            if(item.id !== id){
                item.selected = false;
            }
        });
        this.setState({
            items
        });
    }

    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id}/>
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
            </Container>
        );
    }
}

export default Carousel;